export const ACCOUNT_USERS_STATUSES = {
  NOT_CONFIRMED: {
    text: 'Не подтвержден',
    value: 'NOT_CONFIRMED',
  },
  ACTIVE: {
    text: 'Активен',
    value: 'ACTIVE',
  },
  INACTIVE: {
    text: 'Не активен',
    value: 'INACTIVE',
  },
}

export default {
  ACCOUNT_USERS_STATUSES,
}
