var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SectionCard',{staticClass:"mb-0",attrs:{"label":"Пользователи подрядной организации"}},[_c('AccountUsersList',{attrs:{"headers":[
        { text: 'ID пользователя', value: 'user.id', alias: 'userId', sortable: true },
        { text: 'Дата создания', value: 'user.dateCreate', alias: 'dateCreate', sortable: true },
        { text: 'ФИО', value: 'user.fio', alias: 'fio', sortable: false },
        { text: 'Должность', value: 'user.position', alias: 'position', sortable: false },
        { text: 'Статус', value: 'status.title', alias: 'status', options: _vm.values(_vm.statuses), sortable: false, multiple: false },
      ],"items":_vm.list.items,"count":_vm.list.navCount,"page":_vm.page,"size":_vm.size,"loading":_vm.loading}})],1),(_vm.canAdd)?_c('VFooter',{attrs:{"app":""}},[_c('div',{staticClass:"py-3 grow"},[_c('VBtn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onCreate}},[_vm._v("Создать нового")]),_c('VBtn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onCreateFromDatabase}},[_vm._v("Создать из справочника")])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }